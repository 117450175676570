.thumb{
    width: 100%;
    height: auto;
    transition: 500ms;
}

.tags{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 999;
    opacity: 0;
    transition: 500ms;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}
.tags > div{
    width: 100%;
    height: 100%;
    opacity: 0.5;
    position: absolute;
}
.tags:hover{
    opacity: 1;
}
.tags > span{
    padding: 12px;
    color: white;
    font-size: 24px;
    margin: 12px 12px 0px 12px;
    bottom: 0px;
    left: 0px;
    background-color: #000;
    display: inline-block;
    font-style: italic;
    z-index: 999;
}
.case:nth-child(even) .tags{
    align-items: flex-start;
}

.case-image-container{
    max-height: 40vh;
    overflow: hidden;
    display: flex;
    flex-basis: 0px;
    justify-content: center;
    align-items: center;
}

@media all and (max-width: 600px){
    .thumb{
        width: 100%;
        height: auto;
    }
}
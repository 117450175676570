@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&family=Space+Mono&display=swap");
html {
	background-color: black;
}
* {
	margin: 0px;
	padding: 0px;
	position: relative;
	box-sizing: border-box;
}
body {
	margin: 0;
	font-family: "IBM Plex Sans", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: x-large;
	background-color: #efefef;
}
h2 {
	font-family: "Space Mono", monospace;
}
h4 {
	font-family: "Space Mono", monospace;
	background-color: white;
	padding: 1rem;
	font-size: xx-large;
}
sup {
	font-family: "Space Mono", monospace;
	font-size: x-small;
}
p {
	font-family: "IBM Plex Sans", sans-serif;
	background-color: white;
	padding: 2rem;
}
a {
	display: inline-block;
	text-decoration: none;
	color: rgb(38, 35, 255);
	transition: 500ms;
	background-color: white;
}
a:hover {
	color: rgb(255, 104, 44);
}
.box {
	padding: 2rem;
	background-color: #000;
	color: white;
}
.neolist {
	width: 40%;
	min-height: 50%;
	position: relative;
	padding: 20% 0px 5% 0px;
	left: 50%;
}
.neolist > ul {
	width: 100%;
	border-left: 6px solid black;
	padding-top: 48px;
}

.neolist li {
	width: 100%;
	list-style: none;
	padding: 24px;
	cursor: default;
}
.neolist li h2 {
	background-color: black;
	padding: 12px;
	color: #fff;
	display: inline-block;
	z-index: 99;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.App {
	min-height: 100vh;
}

.logo {
  position: fixed;
	width: 20%;
	height: auto;
	background: #fff;
	padding: 1vw;
  top: 0px;
  right: 5vw;
  z-index: 999999;
}

.logo.opened {
  position: relative;
  top: 0px;
  right: 0px;
  width: 40%;
}

@media all and (max-width: 600px) {
	* {
		margin: 0px;
		padding: 0px;
		position: relative;
		box-sizing: border-box;
	}
	body {
		font-size: large;
	}
	h4 {
		font-family: "Space Mono", monospace;
		background-color: white;
		padding: 1rem;
		font-size: x-large;
	}
	.neolist {
		width: 50%;
		min-height: 50%;
		position: relative;
		padding: 20% 0px;
		left: 50%;
	}
	.neolist > ul {
		width: 100%;
		border-left: 0.3rem solid black;
	}
	.neolist.contact-page > ul {
		width: 100%;
		border-left: 0rem solid black;
	}
	.neolist li {
		width: 200%;
		list-style: none;
		padding: 24px;
		cursor: pointer;
	}
	.neolist li:nth-child(odd) {
		left: -100%;
		text-align: left;
	}
	.neolist li:nth-child(even) {
		left: -100%;
		text-align: left;
	}
  .logo {
    position: fixed;
    width: 40%;
    height: auto;
    background: #fff;
    padding: 1vw;
    top: 0px;
    right: 5vw;
    z-index: 999999;
  }
  
  .logo.opened {
    position: relative;
    top: 0px;
    right: 0px;
    width: 70%;
  }
}

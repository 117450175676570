.case-details-section {
	width: 100vw;
	height: 100vh;
	padding: 0;
	display: flex;
	flex-direction: row;
}
.case-details-details {
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0;
}
.case-details-gallery {
	width: 50%;
}
.case-details-gallery img {
	width: 100%;
	height: auto;
}
.slick-arrow {
	z-index: 9999 !important;
}
.slick-prev {
	left: 6px !important;
}
.slick-next {
	right: 6px !important;
}
.case-details-gallery-slide {
	width: 50vw;
	height: 100vh;
	background-color: black;
	display: flex !important;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.case-details-gallery-slide-img {
	width: 100%;
	height: auto;
	max-width: fit-content;
	max-height: fit-content;
}
.slick-dots {
	bottom: 5% !important;
}
.slick-dots li button {
	background: #fff !important;
	width: 21px;
	height: 21px;
}
.case-details-nav {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.case-details-nav button {
	padding: 1rem;
	border: 0px;
	border-radius: 0px;
	background-color: black;
	color: #fff;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

@media screen and (max-width: 600px) {
	.case-details-section {
		flex-direction: column;
	}
	.case-details-details {
		order: 2;
		width: 100vw;
		height: 50vh;
		justify-content: start;
		padding: 0px;
	}
	.case-details-gallery {
		order: 1;
		height: 50vh;
		width: 100vw;
	}
	.case-details-gallery-slide {
		height: 50vh;
	}
	.case-details-nav {
		position: absolute;
		width: 100%;
		height: 100%;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
	.case-details-nav > div {
		width: 32px;
		height: 32px;
	}
	.case-details-nav > div > button {
		width: 100%;
		height: 100%;
	}
	.case-details-nav > div > button > span {
		display: none;
	}
	.case-details-nav > div > button .arrows {
		width: 24px;
		height: 24px;
		font-size: 24px;
		overflow: visible;
	}
}

.case{
    width: 100%;
    list-style: none;
    padding: 24px;
    cursor: pointer;
}
.case div:first-of-type{
    z-index: 999;
}
.case h2{
    background-color: black;
    padding: 12px;
    color: #fff;
    display: inline-block;
    z-index: 99;
}

.case:nth-child(even){
    left: -100%;
    text-align: right;
}
@media all and (max-width: 600px){
    .case{
        width: 200%;
        list-style: none;
        padding: 24px;
        cursor: pointer;
    }
    .case:nth-child(odd){
        left: -100%;
        text-align: right;
    }
    .case:nth-child(even){
        left: -100%;
        text-align: right;
    }
}
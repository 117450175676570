.cases{
    width: 40%;
    min-height: 50%;
    position: relative;
    padding: 0% 0px 5% 0px;
    left:50%;
}
.cases > ul{
    width: 100%;
    border-left: 6px solid black;
    padding-top: 48px;
}
@media all and (max-width: 600px){
    .cases{
        width: 50%;
        min-height: 50%;
        position: relative;
        padding: 50% 0px;
        left:50%;
    }
    .cases > ul{
        width: 100%;
        border-left: 0.3rem solid black;
    }
}
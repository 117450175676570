.player-container {
	width: max(70%, 700px);
	height: auto;
	padding-top: 4rem;
	margin: auto;
}
.player-iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0px;
}

@media only screen and (max-width: 800px) {
    .player-container {
        width: 100%;
        height: auto;
        padding-top: 2rem;
        margin: auto;
    }
}